window._ = require("lodash");
require("bootstrap");
import PerfectScrollbar from "perfect-scrollbar";

/**
 * We'll load jQuery and the Bootstrap jQuery plugin which provides support
 * for JavaScript based Bootstrap features such as modals and tabs. This
 * code may be modified to fit the specific needs of your application.
 */
window.Popper = require("popper.js").default;
window.$ = window.jQuery = require("jquery");

window.qr = require("qrcode");
window.qrScanner = require("qr-scanner");
window.toastr = require("toastr");
require("bootstrap");
//metismenu
window.metismenu = require("metismenu");
require("./scripts/dashboard");
window.html2canvas = require("html2canvas");
try {
} catch (e) {}

window.datatable = require("datatables.net");
window.datatable_buttons = require("datatables.net-buttons-bs4");
require("datatables.net-buttons/js/buttons.colVis.js");
require("datatables.net-buttons/js/buttons.html5.js");
require("datatables.net-buttons/js/buttons.print.js");
window.datatable_select = require("datatables.net-select-bs4");
window.datatable_responsive = require("datatables.net-responsive-bs4");

/**
 * We'll load the axios HTTP library which allows us to easily issue requests
 * to our Laravel back-end. This library automatically handles sending the
 * CSRF token as a header based on the value of the "XSRF" token cookie.
 */

window.axios = require("axios");

window.axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

/**
 * Echo exposes an expressive API for subscribing to channels and listening
 * for events that are broadcast by Laravel. Echo and event broadcasting
 * allows your team to easily build robust real-time web applications.
 */

// import Echo from 'laravel-echo';

// window.Pusher = require('pusher-js');

// window.Echo = new Echo({
//     broadcaster: 'pusher',
//     key: process.env.MIX_PUSHER_APP_KEY,
//     cluster: process.env.MIX_PUSHER_APP_CLUSTER,
//     forceTLS: true
// });

var body = $("body");
var html = $("html");

function quixSettings({
  typography,
  version,
  layout,
  navheaderBg,
  headerBg,
  sidebarStyle,
  sidebarBg,
  sidebarPosition,
  headerPosition,
  containerLayout,
  direction,
}) {
  this.typography = typography || "poppins";
  this.version = version || "light";
  this.layout = layout || "vertical";
  this.navheaderBg = navheaderBg || "color_1";
  this.headerBg = headerBg || "color_1";
  this.sidebarStyle = sidebarStyle || "full";
  this.sidebarBg = sidebarBg || "color_1";
  this.sidebarPosition = sidebarPosition || "static";
  this.headerPosition = headerPosition || "static";
  this.containerLayout = containerLayout || "wide";
  this.direction = direction || "ltr";

  this.manageTypography();
  this.manageVersion();
  this.manageLayout();
  this.manageNavHeaderBg();
  this.manageHeaderBg();
  this.manageSidebarStyle();
  this.manageSidebarBg();
  this.manageSidebarPosition();
  this.manageHeaderPosition();
  this.manageContainerLayout();
  this.manageRtlLayout();
  this.manageResponsiveSidebar();
}

quixSettings.prototype.manageVersion = function () {
  switch (this.version) {
    case "light":
      body.attr("data-theme-version", "light");
      break;
    case "dark":
      body.attr("data-theme-version", "dark");
      break;
    case "transparent":
      body.attr("data-theme-version", "transparent");
      break;
    default:
      body.attr("data-theme-version", "light");
  }
};

quixSettings.prototype.manageTypography = function () {
  switch (this.version) {
    case "poppins":
      body.attr("data-typography", "poppins");
      break;
    case "roboto":
      body.attr("data-typography", "roboto");
      break;
    case "opensans":
      body.attr("data-typography", "opensans");
      break;
    default:
      body.attr("data-typography", "poppins");
  }
};

quixSettings.prototype.manageLayout = function () {
  switch (this.layout) {
    case "horizontal":
      this.sidebarStyle === "overlay"
        ? body.attr("data-sidebar-style", "full")
        : body.attr("data-sidebar-style", `${this.sidebarStyle}`);
      body.attr("data-layout", "horizontal");
      break;
    case "vertical":
      body.attr("data-layout", "vertical");
      break;
    default:
      body.attr("data-layout", "vertical");
  }
};

quixSettings.prototype.manageNavHeaderBg = function () {
  switch (this.navheaderBg) {
    case "color_1":
      body.attr("data-nav-headerbg", "color_1");
      break;
    case "color_2":
      body.attr("data-nav-headerbg", "color_2");
      break;
    case "color_3":
      body.attr("data-nav-headerbg", "color_3");
      break;
    case "color_4":
      body.attr("data-nav-headerbg", "color_4");
      break;
    case "color_5":
      body.attr("data-nav-headerbg", "color_5");
      break;
    case "color_6":
      body.attr("data-nav-headerbg", "color_6");
      break;
    case "color_7":
      body.attr("data-nav-headerbg", "color_7");
      break;
    case "color_8":
      body.attr("data-nav-headerbg", "color_8");
      break;
    case "color_9":
      body.attr("data-nav-headerbg", "color_9");
      break;
    case "color_10":
      body.attr("data-nav-headerbg", "color_10");
      break;
    case "image_1":
      body.attr("data-nav-headerbg", "image_1");
      break;
    case "image_2":
      body.attr("data-nav-headerbg", "image_2");
      break;
    case "image_3":
      body.attr("data-nav-headerbg", "image_3");
      break;
    default:
      body.attr("data-nav-headerbg", "color_1");
  }
};

quixSettings.prototype.manageHeaderBg = function () {
  switch (this.headerBg) {
    case "color_1":
      body.attr("data-headerbg", "color_1");
      break;
    case "color_2":
      body.attr("data-headerbg", "color_2");
      break;
    case "color_3":
      body.attr("data-headerbg", "color_3");
      break;
    case "color_4":
      body.attr("data-headerbg", "color_4");
      break;
    case "color_5":
      body.attr("data-headerbg", "color_5");
      break;
    case "color_6":
      body.attr("data-headerbg", "color_6");
      break;
    case "color_7":
      body.attr("data-headerbg", "color_7");
      break;
    case "color_8":
      body.attr("data-headerbg", "color_8");
      break;
    case "color_9":
      body.attr("data-headerbg", "color_9");
      break;
    case "color_10":
      body.attr("data-headerbg", "color_10");
      break;
    case "transparent":
      body.attr("data-headerbg", "transparent");
      break;
    case "gradient_1":
      body.attr("data-headerbg", "gradient_1");
      break;
    case "gradient_2":
      body.attr("data-headerbg", "gradient_2");
      break;
    case "gradient_3":
      body.attr("data-headerbg", "gradient_3");
      break;
    default:
      body.attr("data-headerbg", "color_1");
  }
};

quixSettings.prototype.manageSidebarStyle = function () {
  switch (this.sidebarStyle) {
    case "full":
      body.attr("data-sidebar-style", "full");
      break;
    case "mini":
      body.attr("data-sidebar-style", "mini");
      break;
    case "compact":
      body.attr("data-sidebar-style", "compact");
      break;
    case "modern":
      body.attr("data-sidebar-style", "modern");
      break;
    case "icon-hover":
      body.attr("data-sidebar-style", "icon-hover");

      $(".quixnav").hover(
        function () {
          $("#main-wrapper").addClass("icon-hover-toggle");
        },
        function () {
          $("#main-wrapper").removeClass("icon-hover-toggle");
        }
      );
      break;
    case "overlay":
      this.layout === "horizontal"
        ? body.attr("data-sidebar-style", "full")
        : body.attr("data-sidebar-style", "overlay");
      break;
    default:
      body.attr("data-sidebar-style", "full");
  }
};

quixSettings.prototype.manageSidebarBg = function () {
  switch (this.sidebarBg) {
    case "color_1":
      body.attr("data-sibebarbg", "color_1");
      break;
    case "color_2":
      body.attr("data-sibebarbg", "color_2");
      break;
    case "color_3":
      body.attr("data-sibebarbg", "color_3");
      break;
    case "color_4":
      body.attr("data-sibebarbg", "color_4");
      break;
    case "color_5":
      body.attr("data-sibebarbg", "color_5");
      break;
    case "color_6":
      body.attr("data-sibebarbg", "color_6");
      break;
    case "color_7":
      body.attr("data-sibebarbg", "color_7");
      break;
    case "color_8":
      body.attr("data-sibebarbg", "color_8");
      break;
    case "color_9":
      body.attr("data-sibebarbg", "color_9");
      break;
    case "color_10":
      body.attr("data-sibebarbg", "color_10");
      break;
    case "image_1":
      body.attr("data-sibebarbg", "image_1");
      break;
    case "image_2":
      body.attr("data-sibebarbg", "image_2");
      break;
    case "image_3":
      body.attr("data-sibebarbg", "image_3");
      break;
    default:
      body.attr("data-sibebarbg", "color_1");
  }
};

quixSettings.prototype.manageSidebarPosition = function () {
  switch (this.sidebarPosition) {
    case "fixed":
      (this.sidebarStyle === "overlay" && this.layout === "vertical") ||
      this.sidebarStyle === "modern"
        ? body.attr("data-sidebar-position", "static")
        : body.attr("data-sidebar-position", "fixed");
      break;
    case "static":
      body.attr("data-sidebar-position", "static");
      break;
    default:
      body.attr("data-sidebar-position", "static");
  }
};

quixSettings.prototype.manageHeaderPosition = function () {
  switch (this.headerPosition) {
    case "fixed":
      body.attr("data-header-position", "fixed");
      break;
    case "static":
      body.attr("data-header-position", "static");
      break;
    default:
      body.attr("data-header-position", "static");
  }
};

quixSettings.prototype.manageContainerLayout = function () {
  switch (this.containerLayout) {
    case "boxed":
      if (this.layout === "vertical" && this.sidebarStyle === "full") {
        body.attr("data-sidebar-style", "overlay");
      }
      body.attr("data-container", "boxed");
      break;
    case "wide":
      body.attr("data-container", "wide");
      break;
    case "wide-boxed":
      body.attr("data-container", "wide-boxed");
      break;
    default:
      body.attr("data-container", "wide");
  }
};

quixSettings.prototype.manageRtlLayout = function () {
  switch (this.direction) {
    case "rtl":
      html.attr("dir", "rtl");
      html.addClass("rtl");
      body.attr("direction", "rtl");
      break;
    case "ltr":
      html.attr("dir", "ltr");
      html.removeClass("rtl");
      body.attr("direction", "ltr");
      break;
    default:
      html.attr("dir", "ltr");
      body.attr("direction", "ltr");
  }
};

quixSettings.prototype.manageResponsiveSidebar = function () {
  const innerWidth = $(window).innerWidth();
  if (innerWidth < 1200) {
    body.attr("data-layout", "vertical");
    body.attr("data-container", "wide");
  }

  if (innerWidth > 767 && innerWidth < 1200) {
    body.attr("data-sidebar-style", "mini");
  }

  if (innerWidth < 768) {
    body.attr("data-sidebar-style", "overlay");
  }
};

if (document.querySelector(".quixnav-scroll")) {
  const qs = new PerfectScrollbar(".quixnav-scroll");
}
// const sr = new PerfectScrollbar('.sidebar-right-inner');

//plugin bootstrap minus and plus
$(".btn-number").on("click", function (e) {
  e.preventDefault();

  fieldName = $(this).attr("data-field");
  type = $(this).attr("data-type");
  var input = $("input[name='" + fieldName + "']");
  var currentVal = parseInt(input.val());
  if (!isNaN(currentVal)) {
    if (type == "minus") input.val(currentVal - 1);
    else if (type == "plus") input.val(currentVal + 1);
  } else {
    input.val(0);
  }
});

(function ($) {
  "use strict";

  new quixSettings({
    typography: "roboto",
    version: "light",
    layout: "vertical",
    headerBg: "color_1",
    navheaderBg: "color_1",
    sidebarBg: "color_1",
    sidebarStyle: "full",
    sidebarPosition: "fixed",
    headerPosition: "fixed",
    containerLayout: "wide",
    direction: "ltr",
  });
})(jQuery);
